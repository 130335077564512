import i18n from "@/i18n";

const checkLanguage = (to: any, from: any, next: any) => {
  const language = to.params.lang;
  if (!["en", "ne"].includes(language)) {
    return next("ne");
  }

  if (i18n.locale !== language) {
    i18n.locale = language;
  }
  return next();
};

export default checkLanguage
